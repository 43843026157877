
$( document ).ready(function(e) {
 
    
            
    $(window).scroll(function(element){
        
        var logoText = $('.logo-text');
        var logo = $('.logo');
        var now  = $(this).scrollTop() > 10;
        
        if( now ){
            logo.addClass('logo-small'); 
            logoText.addClass('logo-text-small'); 
        } else {
            logo.removeClass('logo-small'); 
            logoText.removeClass('logo-text-small'); 
        }
        
    }); 
    
    $('.menu-button').on( 'click', function(){
        
        var $this  = $(this);
        var target = $this.data('target');
        
        $this.toggleClass('menu-button-open');	            		         
        $(target).toggleClass('d-none');	            		         
    });  
    
    
    $( '.generic-navi li' ).on('click', function(){
        
        var $this = $(this);
        $this.find('>ul').toggle({
            'start': function (){
                
                $this.toggleClass('generic-navi-active');
            }
        });     
    });
    
    $('.search-header-button').on('click', function(event){

        event.preventDefault();

        var button = $(this);
        var openClass = 'search-header-button-open';
        var searchField = $('.search-header-input');
        var searchFieldParent = searchField.parent();
                
        button.toggleClass(openClass);	            		         
        searchField.toggle();
        searchFieldParent.toggle();
    });
    

    $("#loginButton, .loginButton").click(function(event){

        event.preventDefault();
        window.scrollTo(0, 0);
        var tabUser = document.querySelector('#tab_user')
        var tab = new bootstrap.Tab(tabUser);
        tab.show();
        $("#tab_user").trigger("click");
        
    });
    
    $('.switch-button-cont').each(
        function( index, element ){

            var $element = $(element);
            var $expanded = $(element).find('.expanded');

            var className = $element.find(':checked').attr('class');

            $element.addClass(className);

            $expanded.on('swipeleft',
                function(event){
                    $element.trigger('click', [-1]);
                }
            );
            $expanded.on('swiperight',
                function(event){
                    $element.trigger('click', [1]);
                }
            );

            $element.on('click', function(event, direction){
                
                var EMPTY = 'empty';
                var TRUE = 'true';
                var FALSE = 'false';

                var activeInput = $element.find(':checked');
                var activeClass = activeInput.attr('class');
                var activeValue = activeInput.attr('value').toLowerCase();

                if( $(event.target).hasClass('expanded') ){
                    return false;
                }

                activeInput.removeProp('checked');


                var x = event.offsetX;
                var w = $element.width();

                var classSuffix = '';

                $element.removeClass(activeClass);

                var limit = w/4;

                if( x <= limit || ( direction < 0 &&  activeValue == EMPTY ) ){
                    classSuffix = TRUE;
                }

                if( x > limit && x < limit*3 || ( direction < 0 && activeValue == FALSE ) || ( direction > 0 && activeValue == TRUE ) ){
                    classSuffix = EMPTY;
                }

                if( x >= limit*3 || ( direction > 0 &&  activeValue == EMPTY ) ){
                    classSuffix = FALSE;
                }
                
                activeClass = 'switch-button-' + classSuffix;
                
                if(classSuffix != ''){
                    $(element).removeClass('switch-button-empty');
                    $(element).removeClass('switch-button-true');
                    $(element).removeClass('switch-button-false');
                    id = $element.attr('data-id');
                    $('#'+id).val($element.find('.' + activeClass).val()).trigger("change");
                }                
                
                $element.find('.' + activeClass).prop('checked',true).trigger('change');

                $element.addClass(activeClass);
                
                


            });


        }
    );
	    
});